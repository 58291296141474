import React, { useEffect, useState } from 'react';
import { IReportGroup, getReportGroupIcon, getReportGroupIconOff } from '../../../../reporting/entities/ReportGroup';
import { ReportIconBaseURL } from '../../../../reporting/entities/ReportIcons';
import SectionItem from './SectionItem';

interface IComponentInfo {
    item: IReportGroup;
    collapsible: boolean;
    selected: boolean;
    onClick: (i: IReportGroup) => void;
    onChangeSection: (i: IReportGroup) => void;
    selectedSection: string;
    defaultSection: IReportGroup;
}

const SectionTitle: React.FunctionComponent<IComponentInfo> = ({ item, onClick, selected, onChangeSection, collapsible, selectedSection, defaultSection }) => {

    const [imgSrc, setImgSrc] = useState<string>('');
    const [imgOffSrc, setOffImgSrc] = useState<string>('');
    const [src, setSrc] = useState<string>('');
    const [className, setClassName] = useState<string>('');

    useEffect(() => {
        setImgSrc(getReportGroupIcon(item.configuration));
        setOffImgSrc(getReportGroupIconOff(item.configuration));
        setSrc(selected ? imgSrc : imgOffSrc);
        // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
        setClassName(selected ? 'active' : '');
        setSrc(selected ? imgSrc : imgOffSrc);
        // eslint-disable-next-line
    }, [imgOffSrc, imgSrc, selected]);

    const onSelect = () => {
        collapsible && onClick(item);
    }

    const onMouseOver = () => {
        setClassName('active');
        setSrc(imgSrc);
    }

    const onMouseOut = () => {
        setClassName(selected ? 'active' : '');
        setSrc(selected ? imgSrc : imgOffSrc);
    }

    return (
        <>
            <div onMouseOut={onMouseOut} onMouseOver={onMouseOver} className={`section-header ${className}`} onClick={onSelect}>
                {imgSrc !== ReportIconBaseURL && (
                    <img alt={item.title} className={`m-r-5`} src={src} ></img>
                )}
                <span >
                    {item.title}
                </span>

            </div>
   
            {(!collapsible || selected) && (
                <>
                    {item.groups.filter(q => q.isActive && q.id !== defaultSection.id).map((item2, index2) =>
                        <div key={index2} className={`section-item ${item2.id === selectedSection ? 'active' : ''}  ${item.groups.filter(q => q.isActive && q.id !== defaultSection.id).length === index2 + 1 ? 'last-item' : ''}`} >
                            <SectionItem onClick={onChangeSection} group={item2} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default SectionTitle;
